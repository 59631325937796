<template>
    
  <v-card>
    <v-card-title>
      Crop Price
      <v-spacer></v-spacer>
      <v-btn
        color="secondary"
        @click="(dialog = true)"
      >
      <v-icon left>
        mdi-plus
      </v-icon>
      New Crop Price
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
      <v-row class="mb-2">
        <v-col
        cols="12"
        sm="4"
        >
          <v-select
            :items="stateLists"
            item-text="NAME"
            item-value="STATE_ID"
            label="State"
            outlined
            dense
            v-model="stateQuery"
            @change="getAllPrice()"
            hide-details="auto"
          ></v-select>
        </v-col>
        <v-col
        cols="12"
        sm="4"
        >
          <v-select
            :items="cropLists"
            item-text="Crop"
            item-value="ID"
            label="Crop"
            outlined
            dense
            v-model="cropQuery"
            @change="getAllPrice()"
            hide-details="auto"
          ></v-select>
        </v-col>
        <v-col
        cols="12"
        sm="4"
        >
          <v-select
            :items="yearList"
            label="Year"
            outlined
            dense
            v-model="yearQuery"
            @change="getAllPrice()"
            hide-details="auto"
          ></v-select>
        </v-col>
      </v-row>
      <v-divider></v-divider>

      <v-card-title class="pl-0 pr-0">
        <v-row>
          <v-col
            cols="12"
            sm="6"
            offset-sm="6"
          >
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            outlined
            dense
          ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="cropPriceHeaders"
        :items="cropsPriceTable"
        :items-per-page="5"
        :search="search"
        class="elevation-1"
      >
    
      <template v-slot:[`item.action`]="props">
          <template>
              <v-icon
              dense
              class="mr-2 blue--text" @click="formCropPrice = props.item, dialog = true, mode = 'edit'"
              >
                  mdi-pencil-outline
              </v-icon>
              <v-icon
              dense
              class="mr-2 red--text" @click="deletePriceData = props.item, deletePriceDialog = true"
              >
                  mdi-trash-can-outline
              </v-icon>
          </template>
      </template>
      </v-data-table>
    </v-card-text>

    <v-dialog
      v-model="dialog"
      max-width="50%"
      persistent
    >

      <v-card>
        <v-card-title class="text-h5 secondary white--text justify-center" v-if="mode == 'new'">
          New Crop Price
        </v-card-title>
        <v-card-title class="text-h5 secondary white--text justify-center" v-else>
          Update Crop Price
        </v-card-title>
        <v-card-text class="mt-3">
          <v-row>
            <v-col
              cols="12"
              dense
            >
            <v-select
              :items="cropLists"
              item-text="Crop"
              item-value="ID"
              label="Crop Name"
              required
              outlined
              hide-details="auto"
              dense
              v-model="formCropPrice.CROP_ID"
            ></v-select>
            </v-col>
            <v-col
              cols="12"
              dense
            >
            <v-select
              :items="stateLists"
              label="State"
              item-text="NAME"
              item-value="STATE_ID"
              required
              outlined
              hide-details="auto"
              dense
              v-model="formCropPrice.STATE_ID"
              @change="getDistrictListByState()"
            ></v-select>
            </v-col>
            <v-col
              cols="12"
              dense
            >
            <v-select
              :items="districtLists"
              label="District"
              item-text="NAME"
              item-value="DISTRICT_ID"
              required
              outlined
              hide-details="auto"
              dense
              v-model="formCropPrice.DISTRICT_ID"
            ></v-select>
            </v-col>
            <v-col
              cols="12"
              dense
            >
            <v-text-field
              dense
              outlined
              hide-details="auto"
              required
              label="Minimum Price"
              v-model="formCropPrice.LOW_PRICE"
            ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              dense
            >
            <v-text-field
              dense
              outlined
              hide-details="auto"
              required
              label="Maximum Price"
              v-model="formCropPrice.HIGH_PRICE"
            ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              dense
            >
            <v-text-field
              dense
              outlined
              hide-details="auto"
              required
              label="Average Price"
              v-model="formCropPrice.AVG_PRICE"
            ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            @click="(dialog = false)"
            outlined
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            @click="submitNewPrice()"
            outlined
            v-if="mode == 'new'"
          >
            Save
          </v-btn>
          <v-btn
            color="primary"
            @click="submitUpdatePrice()"
            outlined
            v-else
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <template>
      <v-dialog
      v-model="deletePriceDialog"
      width="600"
      scrollable
      >
      <v-card>
          <v-card-title class="justify-center title-bar-modul">
              <h4>Delete Crop Price</h4>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text class="mt-2">
            <v-row>

              <v-col
                  class="d-flex mt-3"
                  cols="12"
                  sm="3"
              >
                  <v-text-field
                      v-model="deletePriceData.ID"
                      outlined
                      label="Crop Price ID"
                      dense
                      hide-details="auto"
                      readonly
                  ></v-text-field>
              </v-col>
              <v-col
                  class="d-flex mt-3"
                  cols="12"
                  sm="9"
              >
                  <v-text-field
                      v-model="deletePriceData.CROP_NAME"
                      outlined
                      label="Crop Price Name"
                      dense
                      hide-details="auto"
                      readonly
                  ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
              
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                outlined
                @click="deletePriceDialog = false; deletePriceData = ''"
            >
                Cancel
            </v-btn>
            <v-btn
                color="red"
                outlined
                @click="deleteCropPrice();"
            >
                Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template>
      <div class="text-center">
        <v-snackbar
        v-model="snackbar"
        :timeout="timeout"
        color="success"
        >

        {{ snackbarMsg }}

        <template v-slot:action="{ attrs }">
            <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar = false"
            >
            Close
            </v-btn>
        </template>
        </v-snackbar>
      </div>
    </template>
  </v-card>

</template>

<script>
// -- import something here --
import axios from 'axios';

export default {

  data: () => ({
    mode: 'new',
    search: '',
    dialog: false,
    cropLists: [],
    stateLists: [],
    yearList: ['2021','2022','2023'],
    stateQuery: '01',
    cropQuery: 8, 
    yearQuery: '2021', 
    districtLists: [],
    cropPriceHeaders: [
      {
        text: 'Crop',
        align: 'start',
        sortable: false,
        value: 'CROP_NAME',
      },
      { text: 'State', value: 'STATE_NAME' },
      { text: 'District', value: 'DISTRICT_NAME' },
      { text: 'Date', value: 'DATE_CREATED' },
      { text: 'Minimum Price', value: 'LOW_PRICE' },
      { text: 'Maximum Price', value: 'HIGH_PRICE' },
      { text: 'Average Price', value: 'AVG_PRICE' },
      { text: 'Action', value: 'action', width: '10%'},
    ],
    cropsPriceTable: [],

    formCropPrice: {
      ID: "",
      DATE_CREATED: "",
      CROP_ID: "",
      STATE_ID: "",
      DISTRICT_ID: "",
      QUANTITY: "",
      HIGH_PRICE: "",
      LOW_PRICE: "",
      AVG_PRICE: ""
    },

    deletePriceDialog: false,
    deletePriceData: "",
    snackbar: false,
    timeout: 3000,
    snackbarMsg: ""

  }),

  mounted() {
    this.getCropList();
  },

  methods: {
    getAllPrice(){
      axios.get('https://atania.app/api/price/all.php?state=' + this.stateQuery + '&crop=' + this.cropQuery + '&year=' + this.yearQuery, {
          headers: {
              // 'Authorization': 'Bearer ' + this.$store.getters.token,
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).jwt,
          }
      })
      .then((response) => {

          for(let k in response.data){
            for(let l in this.cropLists){
              if(response.data[k].CROP_ID == this.cropLists[l].ID){
                response.data[k].CROP_NAME = this.cropLists[l].Crop
              }
            }
          }

          for(let k in response.data){
            for(let l in this.stateLists){
              if(response.data[k].STATE_ID == this.stateLists[l].STATE_ID){
                response.data[k].STATE_NAME = this.stateLists[l].NAME
              }
            }
          }

          for(let k in response.data){
            for(let l in this.districtLists){
              if(response.data[k].DISTRICT_ID == this.districtLists[l].DISTRICT_ID){
                response.data[k].DISTRICT_NAME = this.districtLists[l].NAME
              }
            }
          }
          // console.log(response.data);
          this.cropsPriceTable = response.data;

      })
      .catch(error => {
          console.log(error);
      })
    },

    getCropList(){
      axios.get('https://atania.app/api/crop/all.php', {
          headers: {
              // 'Authorization': 'Bearer ' + this.$store.getters.token,
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).jwt,
          }
      })
      .then((response) => {
          this.cropLists = response.data;
          this.getStateList();

      })
      .catch(error => {
          console.log(error);
      })
    },

    getStateList(){
      axios.get('https://atania.app/api/state/all.php', {
          headers: {
              // 'Authorization': 'Bearer ' + this.$store.getters.token,
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).jwt,
          }
      })
      .then((response) => {
          this.stateLists = response.data;
          this.getDistrictList();

      })
      .catch(error => {
          console.log(error);
      })
    },

    getDistrictList(){
      axios.get('https://atania.app/api/district/all.php', {
          headers: {
              // 'Authorization': 'Bearer ' + this.$store.getters.token,
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).jwt,
          }
      })
      .then((response) => {
          this.districtLists = response.data;
          this.getAllPrice();

      })
      .catch(error => {
          console.log(error);
      })
    },

    getDistrictListByState(){
      axios.get('https://atania.app/api/district/get.php?id=' + this.formCropPrice.STATE_ID, {
          headers: {
              // 'Authorization': 'Bearer ' + this.$store.getters.token,
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).jwt,
          }
      })
      .then((response) => {
          this.districtLists = response.data;

      })
      .catch(error => {
          console.log(error);
      })
    },

    submitNewPrice(){

      var today = new Date();
      var dd = today.getDate();

      var mm = today.getMonth()+1; 
      var yyyy = today.getFullYear();
      if(dd < 10) 
      {
          dd = '0' + dd;
      } 

      if(mm < 10) 
      {
          mm = '0' + mm;
      } 

      today = yyyy + '-' + mm + '-' + dd;

      const payload = {
        DATE_CREATED: today,
        CROP_ID: this.formCropPrice.CROP_ID,
        STATE_ID: this.formCropPrice.STATE_ID,
        DISTRICT_ID: this.formCropPrice.DISTRICT_ID,
        QUANTITY: "",
        HIGH_PRICE: this.formCropPrice.HIGH_PRICE,
        LOW_PRICE: this.formCropPrice.LOW_PRICE,
        AVG_PRICE: this.formCropPrice.AVG_PRICE
      };

      axios.post('https://atania.app/api/price/new.php', payload, {
          headers: {
              // 'Authorization': 'Bearer ' + this.$store.getters.token,
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).jwt,
          }
      })
      .then((response) => {
        console.log(response);     
        this.snackbarMsg = 'New crop price successfully added!';
        this.snackbar = true;
        this.getAllPrice();
        this.dialog = false;

        this.formCropPrice = {
          ID: "",
          DATE_CREATED: "",
          CROP_ID: "",
          STATE_ID: "",
          DISTRICT_ID: "",
          QUANTITY: "",
          HIGH_PRICE: "",
          LOW_PRICE: "",
          AVG_PRICE: ""
        }
          
      })
      .catch(error => {
        console.log(error.response);
      });
    },

    submitUpdatePrice(){

      const payload = {
        ID: this.formCropPrice.ID,
        DATE_CREATED: this.formCropPrice.DATE_CREATED,
        CROP_ID: this.formCropPrice.CROP_ID,
        STATE_ID: this.formCropPrice.STATE_ID,
        DISTRICT_ID: this.formCropPrice.DISTRICT_ID,
        QUANTITY: this.formCropPrice.QUANTITY,
        HIGH_PRICE: this.formCropPrice.HIGH_PRICE,
        LOW_PRICE: this.formCropPrice.LOW_PRICE,
        AVG_PRICE: this.formCropPrice.AVG_PRICE
      };

      axios.post('https://atania.app/api/price/edit.php', payload, {
          headers: {
              // 'Authorization': 'Bearer ' + this.$store.getters.token,
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).jwt,
          }
      })
      .then((response) => {
        console.log(response);     
        this.snackbarMsg = 'Crop price successfully updated!';
        this.snackbar = true;
        this.getAllPrice();
        this.dialog = false;

        this.mode = 'new';

        this.formCropPrice = {
          ID: "",
          DATE_CREATED: "",
          CROP_ID: "",
          STATE_ID: "",
          DISTRICT_ID: "",
          QUANTITY: "",
          HIGH_PRICE: "",
          LOW_PRICE: "",
          AVG_PRICE: ""
        }
          
      })
      .catch(error => {
        console.log(error.response);
      });
    },

    deleteCropPrice(){

      axios.get('https://atania.app/api/price/delete.php?id=' + this.deletePriceData.ID, {
          headers: {
              // 'Authorization': 'Bearer ' + this.$store.getters.token,
              'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('user')).jwt,
          }
      })
      .then((response) => {
          console.log(response.data);
          if(response.data.msg == 'succesful') {
            this.snackbarMsg = 'Your data successfully deleted!';
            this.snackbar = true;
            this.getAllPrice();
            this.deletePriceDialog = false;
          }
          

      })
      .catch(error => {
          console.log(error);
      })
    }
  },

};
</script>

<style lang="scss">
@import '~scss/main';


</style>


